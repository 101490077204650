<template>
  <div class="main">
    <div class="wrapper">
      <v-container grid-list-xs xs="12" lg="6" sm="5" md="4">
        <!-- loader -->
        <div class="d-flex flex-column" v-if="isLoading">
          <div class="flex justify-center">
            <v-img
              max-width="600"
              :src="require('@/assets/img/prepare.svg')"
            ></v-img>
          </div>
          <span class="text-center text-h5 leaf--text mt-3">
            {{ message }}
          </span>
          <span class="d-flex justify-center mt-3">
            <v-progress-circular
              :size="30"
              color="green"
              indeterminate
            ></v-progress-circular>
          </span>
        </div>
        <div v-if="!isLoading" class="merchant-container py-15">
          <div
            class="d-flex justify-center align-center flex-column"
            v-if="isError"
          >
            <div class="not_found_container">
              <v-img
                class="home_not_found_img"
                :src="require('@/assets/img/home_closed.png')"
              ></v-img>
              <v-img
                class="closed_x_img"
                :src="require('@/assets/img/close_x.png')"
              ></v-img>
            </div>
            <h5 class="text-center text-h5 mt-8 font-weight-bold">
              Shop <span class="red--text">Not Found</span>, <br />Please Check
              the Shoplink
            </h5>
          </div>
          <div
            class="d-flex justify-center align-center flex-column"
            v-if="isExpired"
          >
            <div class="not_found_container">
              <v-img
                class="home_not_found_img"
                :src="require('@/assets/img/isExpired.png')"
              ></v-img>
            </div>
            <h5 class="text-center text-h5 mt-8 font-weight-bold">
              Your Session Has Expired
            </h5>
            <div class="is_expired_message text-center text-h8 mt-16">
              Kindly ask a staff member to generate new QR code
            </div>
          </div>
          <div
            v-if="!isExpired && !isError && !showOperation"
            style="width: 100%"
            class="d-flex justify-center align-center flex-column"
          >
            <img
              width="100px"
              height="100px"
              v-bind:src="logo_image_url"
              default=""
            />
            <div class="text-center text-h4 font-weight-bold mt-3">
              {{ this.franchise.name }}
            </div>
          </div>
          <div
            v-if="showOperation && !isLoading"
            class="d-flex justify-center align-center flex-column"
          >
            <div class="image_container">
              <v-img
                class="home_closed_img"
                :src="require('@/assets/img/home_closed.png')"
              ></v-img>
              <v-img
                class="closed_outlet_img"
                :src="require('@/assets/img/closed_outlet.png')"
              ></v-img>
            </div>
          </div>
          <div class="text-center text-h6 font-weight-bold mt-5">
            {{ message }}
          </div>
          <div v-if="!isClosed && !isExpired">
            <v-row
              class="mt-10"
              justify="center"
              v-if="
                shopLinkType == 'STATIC_QR' || shopLinkType === 'SESSION_QR'
              "
            >
              <v-alert
                v-if="franchise.announcement"
                icon="mdi-sale"
                border="left"
                class="text-center"
              >
                <div class="text-h6 font-weight-bold">
                  {{ announcement }}
                </div>
              </v-alert>
            </v-row>
            <v-row v-if="shopLinkType == 'SHOP_LINK'" width="100%">
              <v-col width="100%" class="d-flex">
                <v-select
                  @change="onChangeOutlet"
                  color="#8E8E8E"
                  item-color="teal"
                  full-width
                  class="white"
                  :items="outletList"
                  label="Select a branch to order from"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row width="100%">
              <v-col width="100%" class="d-flex">
                <v-btn
                  @click="navigateToProduct()"
                  :disabled="!selectedOutlet && shopLinkType == 'SHOP_LINK'"
                  width="100%"
                  class="start-order-color"
                  color="#F85757"
                  elevation="5"
                  large
                >
                  <v-progress-circular
                    v-if="isBtnLoading"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                  <span v-else> Start Ordering </span></v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>

      <span class="card_operation_time mt-3" v-if="showOperation && !isLoading">
        <div class="card_operation_time pa-4 mb-3">
          <div
            class="nunito mb-2 d-flex justify-center text-subtitle-1 text-center white--text text-capitalize"
          >
            Our operation time
          </div>
          <div v-for="(value, i) in operations" :key="i">
            <div class="d-flex justify-space-between">
              <div class="d-flex white--text">
                {{ value.name }}
              </div>
              <div
                class="white--text"
                v-for="(time, idx) in value.timeSlots"
                :key="idx"
              >
                {{ dayjs(time.startTime).format("hh:mm A") }} -
                {{ dayjs(time.endTime).format("hh:mm A") }}
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="made-with-love-container">
      <div>Made with <span style="color: red">♥</span> by</div>
      <img
        src="https://s3.ap-southeast-1.amazonaws.com/assets.getorders/63f882f6f819fb00086bcb9e.png"
        alt="Vue.js logo"
      />
    </div>

    <div
      class="policy-container"
      v-if="!isError && franchise && franchise.policy"
    >
      <v-icon size="20" class="mdi_icon_policy">mdi-shield-check</v-icon>
      <p>Policy</p>
      <v-icon size="20" class="mdi_chevron_down" @click="toggleDropdown"
        >mdi-chevron-down</v-icon
      >
      <div
        id="dropdownContent"
        class="dropdown-content"
        v-show="isDropdownOpen"
      >
        <div class="flex justify-start align-center">
          <v-icon size="20" class="mdi_icon_policy">mdi-shield-check</v-icon>
          <p>{{ franchise.policy }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import jwt from "jsonwebtoken";
import { setOptions, bootstrap } from "vue-gtag";

export default {
  name: "HelloWorld",

  data() {
    return {
      isDropdownOpen: false,
      isOpenFromMultipleTimeSlot: false,
      outletList: [],
      isLoading: true,
      isExpired: false,
      isBtnLoading: false,
      isClosed: false,
      showOperation: false,
      shopLinkType: "", // STATIC_QR , SESSION_QR, SHOP_LINK
      operations: null,
      message: "",
      announcement: "",
      isError: false,
      logo_image_url: "",
      data: {
        email: "",
        password: "",
      },
      gid: {
        config: { id: "UA-45208413-12" },
      },
    };
  },

  computed: {
    ...mapGetters({
      // product: "getProduct",
      franchise: "getFranchise",
      user: "getUsers",
      selectedOutlet: "getSelectedOutlet",
    }),
    dayjs() {
      return require("dayjs");
    },
  },

  watch: {
    franchise: "updatedFranchise",
  },

  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    onChangeOutlet(outlet_id) {
      const selectedOutlet = this.outletList.find(
        (item) => item.value == outlet_id
      );
      this.$store.dispatch("updateSO", selectedOutlet);
    },
    navigateToProduct() {
      this.isBtnLoading = true;
      const outlet_id = this.selectedOutlet
        ? this.selectedOutlet.value
        : this.$route.params.outlet_id;
      if (this.$route.params.outlet_id) {
        // STATIC QR
        this.$store.dispatch("fetchProduct", outlet_id).then((result) => {
          if (result) {
            this.isBtnLoading = false;
            this.$router.push({
              name: "Products",
              params: {
                child: this.$route.params.child,
                outlet_id,
                table_id: this.$route.params.table_id,
              },
            });
          }
        });
        this.onChangeOutlet(outlet_id); // update selected outlet to store
        this.$store.dispatch("setSelectedTableId", this.$route.params.table_id);
      } else if (this.$route.params.session) {
        // SESSION QR
        const _outletIdFromSession = this.outletList[0].value || 0;
        this.onChangeOutlet(_outletIdFromSession); // update selected outlet to store
        this.$store
          .dispatch("fetchProduct", _outletIdFromSession)
          .then((result) => {
            if (result) {
              this.isBtnLoading = false;
              this.$router.push({
                name: "SessionProducts",
                params: { session: this.$route.params.session },
              });
            }
          });
      } else {
        // SHOP LINK
        localStorage.removeItem("order");
        this.$store.dispatch("resetOrder");
        this.$store.dispatch("fetchProduct", outlet_id).then((result) => {
          if (result) {
            this.isBtnLoading = false;
            this.$router.push({
              name: "ShoplinkProducts",
              params: { child: this.$route.params.child, page: "products" },
            });
          }
        });
        this.onChangeOutlet(outlet_id); // update selected outlet to store
      }
    },
    updatedFranchise() {
      if (this.franchise && !this.franchise.error) {
        this.logo_image_url = this.franchise.logo
          ? "https://s3.ap-southeast-1.amazonaws.com/assets.getorders/" +
            this.franchise.logo
          : require("@/assets/img/slurp_logo.png");
        // if (new Date() > new Date(this.franchise.expired_at)) {
        //     this.isLoading = false;
        //     this.isClosed = true;
        //     this.message = `${this.franchise.name} is now closed`;
        //   }
        let expiry =
          new Date() > new Date(this.franchise.expired_at) ? true : false;
        let offline = this.franchise.offline;
        // this.dateslot_check(this.franchise.date_slot);
        if (expiry == true || offline == true) {
          this.checkValidity();
        } else {
          Promise.all([
            // this.exception_check(),
            this.dateslot_check(),
            // this.timeLoop(),
          ]).then((values) => {
            console.log(JSON.parse(JSON.stringify(values)));
            if (values[0]) {
              values.forEach((d) => {
                this.isClosed = d.isClosed;
                this.isLoading = d.isLoading;
                this.message = d.message;
                this.showOperation = d.showOperation;
                this.operations = d.operations;
              });
            } else {
              this.setListOutlet();
              this.post_check();
            }
          });
        }
      }
    },

    initGA(_id) {
      if (_id) this.gid.includes = [{ id: _id }];
      setOptions(this.gid);

      bootstrap();

      this.$gtag.pageview("/product");
    },

    setListOutlet() {
      const session = this.$route.params.session;
      if (this.franchise) {
        this.announcement = this.franchise.announcement;

        if (this.$route.params.outlet_id) {
          this.shopLinkType = "STATIC_QR";
          this.onChangeOutlet(this.$route.params.outlet_id); // update selected outlet to store
          // this.navigateToProduct(this.$route.params.outlet_id)
          this.getCartFromLocalStorage();
        } else if (session) {
          this.getCartFromLocalStorage();
          this.shopLinkType = "SESSION_QR";
        } else {
          this.shopLinkType = "SHOP_LINK";
        }
      }
    },

    tf_disp(t) {
      var ta = t.split(":"),
        am = "AM";
      var hr = parseInt(ta);
      if (hr > 11) {
        hr -= 12;
        am = "PM";
      }

      var time = hr != 0 ? `${hr}:${ta[1]}${am}` : `12:${ta[1]}${am}`;

      return time;
    },
    getCartFromLocalStorage() {
      const cart_list_local = JSON.parse(localStorage.getItem("cart_list"));
      if (cart_list_local) {
        this.$store.dispatch("updateCart", cart_list_local);
      }
    },
    post_check() {
      if (!this.isClosed) {
        this.isLoading = false;
        if (this.$route.query.k) {
          let userz = jwt.verify(this.$route.query.k, "SecretPie");
          this.$store.dispatch("insertUser", userz);
        } else this.$store.dispatch("fetchUsers");

        if (
          this.franchise.facebook_pixel_id &&
          this.franchise.facebook_pixel_id !== ""
        ) {
          window.fbq("init", this.franchise.facebook_pixel_id);
          window.fbq("track", "PageView");
        }
        if (
          this.franchise.google_analytic_id &&
          this.franchise.google_analytic_id !== ""
        ) {
          this.initGA(this.franchise.google_analytic_id || null);
        }
      }
    },
    tf_num(t) {
      t = t.replace(":", "");
      return parseInt(t);
    },
    checkValidity() {
      this.isLoading = false;
      this.isClosed = true;
      this.message = `${this.franchise.name} is now closed`;
    },

    getTodaySlot(day_slot) {
      const today = dayjs().format("dddd");
      // const today = "Tuesday"
      const todaySlot = day_slot.find((day) => day.name === today);
      if (todaySlot) {
        return todaySlot;
      }
      return null;
    },

    getAvailibityTime(timeSlots) {
      let isAvailable = false;
      const now = parseInt(dayjs().format("HHmm"));
      timeSlots.map((time) => {
        const from = parseInt(dayjs(time.startTime).format("HHmm"));
        const to = parseInt(dayjs(time.endTime).format("HHmm"));
        if (now >= from && now <= to) {
          isAvailable = true;
        }
      });
      return isAvailable;
    },
    // OLD logic
    // dateslot_check(v) {
    //   v = v || this.franchise.day_slot;
    //   // console.log(v, 'v');
    //   // console.log(this.franchise.day_slot, 'slot');
    //   let open = v && v.length > 0 ? v.filter((d) => d.status) : [];
    //   if (open.length > 0) {
    //     let test = [];
    //     open.forEach((d) => {
    //       if (this.checkOpen(d)) {
    //         test.push({ ...d });
    //       }
    //     });
    //     if (test.length == 0) {
    //       return {
    //         isLoading: false,
    //         isClosed: true,
    //         showOperation: true,
    //         operations: open,
    //         message: `${this.franchise.name} is currently closed`,
    //       };
    //     }
    //   }
    // },
    // dateslot_check new
    dateslot_check(v) {
      v = v || this.franchise.day_slot;
      // console.log(v, 'v');
      // console.log(this.franchise.day_slot, 'slot');
      if (!v.length) return null;

      let open = v && v.length > 0 ? v.filter((d) => d.isOpen) : [];
      const todaySlot = this.getTodaySlot(v);
      if (todaySlot && !todaySlot.isOpen) {
        return {
          isLoading: false,
          isClosed: true,
          showOperation: true,
          operations: open,
          message: `${this.franchise.name} is currently closed`,
        };
      } else {
        console.log("todaySlot", JSON.stringify(todaySlot));
        const isClosedByHours = this.getAvailibityTime(todaySlot.timeSlots);
        console.log("isClosedByHours", isClosedByHours);
        if (!isClosedByHours) {
          return {
            isLoading: false,
            isClosed: true,
            showOperation: true,
            operations: open,
            message: `${this.franchise.name} is currently closed`,
          };
        } else {
          return null;
        }
      }
    },
    exception_check(v) {
      v = v || this.franchise.exception_day;
      let closed = v && v.length > 0 ? v.filter((d) => d.closed) : [];
      let k = [];
      if (closed.length > 0) {
        closed.forEach((d) => {
          let today = parseInt(dayjs(new Date()).format("DDMMYYYY")),
            start = parseInt(dayjs(d.date.start).format("DDMMYYYY")),
            end = parseInt(dayjs(d.date.end).format("DDMMYYYY"));

          // console.log("here", today, start, end);
          // console.log("first", today >= start ? true : false);
          // console.log("second", today <= end ? true : false);

          if (today >= start && today <= end) {
            k.push({
              isLoading: false,
              isClosed: true,
              message: `${this.franchise.name} is closed for ${d.name}. ${this.franchise.name} is out from ${d.date.start} to ${d.date.end}. `,
            });
          }
        });
      }
      return k;
    },
    // checkOpen(v) {
    //   let week = [
    //     "Monday",
    //     "Tuesday",
    //     "Wednesday",
    //     "Thursday",
    //     "Friday",
    //     "Saturday",
    //     "Sunday",
    //   ];
    //   console.log(JSON.parse(JSON.stringify(v)))
    //   let first = week.findIndex((d) => d == v.date.start);
    //   let last = week.findIndex((d) => d == v.date.end);
    //   let weekly = week.slice(first, last + 1);

    //   let today = dayjs(new Date()).format("dddd");

    //   // console.log("week", weekly);
    //   // console.log("today", today);

    //   return weekly.includes(today);
    // },
    // OLD logic
    // timeLoop() {
    //   let open = this.franchise.day_slot ? this.franchise.day_slot.filter((d) => d.status) : [];
    //   let k = [];

    //   // let checkDay = () => {};

    //   if (this.franchise.day_slot && this.franchise.day_slot.length > 0) {
    //     this.franchise.day_slot.forEach((d) => {
    //       if (this.checkOpen(d)) {
    //         var from_time = parseInt(dayjs(d.time.from).format("HHmm")),
    //           to_time = parseInt(dayjs(d.time.to).format("HHmm"));
    //         var current_time = parseInt(dayjs().format("HHmm"));

    //         // console.log("here", current_time, from_time, to_time);
    //         // console.log("first", current_time >= from_time ? true : false);
    //         // console.log("second", current_time <= to_time ? true : false);
    //         // console.log('is close', current_time >= from_time && current_time <= to_time);

    //         if (current_time >= from_time && current_time <= to_time) {
    //           this.isOpenFromMultipleTimeSlot = true
    //           // all working
    //         } else {
    //           // k.push({
    //           //   isLoading: false,
    //           //   isClosed: true,
    //           //   showOperation: true,
    //           //   operations: open,
    //           //   message: `${this.franchise.name} is currently closed`,
    //           // });
    //         }
    //       }
    //     });
    //     console.log('isOpenFromMultipleTimeSlot', this.isOpenFromMultipleTimeSlot);

    //     if (this.isOpenFromMultipleTimeSlot == false) {
    //       k.push({
    //         isLoading: false,
    //         isClosed: true,
    //         showOperation: true,
    //         operations: open,
    //         message: `${this.franchise.name} is currently closed`,
    //       });
    //     }
    //     return k;
    //   } else {
    //     let l = this.timeslot_check();
    //     if (l) {
    //       k.push(this.timeslot_check());
    //     }
    //     return k;
    //   }
    timeLoop() {
      let open = this.franchise.day_slot
        ? this.franchise.day_slot.filter((d) => d.isOpen)
        : [];
      let k = [];

      // let checkDay = () => {};

      if (this.franchise.day_slot && this.franchise.day_slot.length > 0) {
        this.franchise.day_slot.forEach((d) => {
          if (this.checkOpen(d)) {
            var from_time = parseInt(dayjs(d.time.from).format("HHmm")),
              to_time = parseInt(dayjs(d.time.to).format("HHmm"));
            var current_time = parseInt(dayjs().format("HHmm"));

            if (current_time >= from_time && current_time <= to_time) {
              this.isOpenFromMultipleTimeSlot = true;
              // all working
            } else {
              // k.push({
              //   isLoading: false,
              //   isClosed: true,
              //   showOperation: true,
              //   operations: open,
              //   message: `${this.franchise.name} is currently closed`,
              // });
            }
          }
        });
        console.log(
          "isOpenFromMultipleTimeSlot",
          this.isOpenFromMultipleTimeSlot
        );

        if (this.isOpenFromMultipleTimeSlot == false) {
          k.push({
            isLoading: false,
            isClosed: true,
            showOperation: true,
            operations: open,
            message: `${this.franchise.name} is currently closed`,
          });
        }
        return k;
      } else {
        let l = this.timeslot_check();
        if (l) {
          k.push(this.timeslot_check());
        }
        return k;
      }
    },

    timeslot_check(timeslot) {
      if (!timeslot || timeslot.length == 0) {
        if (this.franchise.timeslots && this.franchise.timeslots.length > 1) {
          timeslot = this.franchise.timeslots;
        } else {
          timeslot = [];
          //No timeslot
          // if (this.franchise.offline == true ) { //Outlet is not set to offline
          //   $session.offline = false;
          //   valid_message = '';
          // }
        }
      }
      if (timeslot.length > 1) {
        var from_time = timeslot[0],
          to_time = timeslot[timeslot.length - 1];
        var current_time = parseInt(dayjs().format("HHmm"));
        if (
          current_time >= this.tf_num(from_time) &&
          current_time <= this.tf_num(to_time)
        ) {
          //Everything ok
          // if ($session.offline == true && def_offline == false) { //Outlet is not set to offline
          //   $session.offline = false;
          //   valid_message = '';
          // }
        } else {
          return {
            isLoading: false,
            isClosed: true,
            message: `${
              this.franchise.name
            } is now closed. Our operating time is from ${this.tf_disp(
              from_time
            )} to ${this.tf_disp(to_time)}`,
          };
        }
      }
    },
  },
  beforeMount() {
    this.$gtag.event("Main Page", { method: "Click" });
    this.isLoading = true;
    const { outlet_id } = this.$route.params;
    const currentOutletOrSession = outlet_id || this.$route.params.session;
    const previousOutletOrSession = localStorage.getItem(
      "previousOutletOrSession"
    );
    if (previousOutletOrSession !== currentOutletOrSession) {
      this.$store.dispatch("updateCart", []);
      localStorage.removeItem("cart_list");
      localStorage.setItem("previousOutletOrSession", currentOutletOrSession);
    }
    if (!this.$route.params.child && !this.$route.params.session) {
      this.isLoading = false;
      window.location.href = "https://getslurp.com/features/getorders/";
    } else if (this.$route.params.child == "demo") {
      this.isLoading = false;
      window.location.reload();
    } else {
      if (this.$route.params.session) {
        this.$store.dispatch("updateIsFromSession", true);
      }

      let code = this.$route.params.child || this.$route.params.session;
      const payload = {
        code,
        table_id: this.$route.params.table_id,
      };
      // session url
      if (payload.code == "session") {
        const session = this.$route.params.outlet_id;
        payload.code = session;
        const newRoute = { name: "HomeSESSION", params: { session } };
        this.$router.push(newRoute);
      }
      this.$store.dispatch("fetchFranchise", payload).then((r) => {
        const outlet =
          r.outlets &&
          r.outlets.map((item) => ({ value: item.id, text: item.name }));
        this.outletList = outlet;

        if (r.error) {
          this.isLoading = false;
          this.isError = true;
          this.isClosed = true;
          // this.message = "Shop not found, please recheck the shop link";
        }

        if (r.is_expired) {
          this.isLoading = false;
          this.isExpired = true;
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.merchant-container {
  width: 100%;

  .start-order-color {
    color: white;
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.text-theme {
  color: #98fb98;
}

.made-with-love-container {
  display: flex;
  position: sticky;
  bottom: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
    margin-left: 5px;
  }
}

.policy-container {
  display: flex;
  position: sticky;
  bottom: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: gray;

  .mdi_icon_policy {
    bottom: 10px;
    color: #f85757;
  }

  .mdi_chevron_down {
    bottom: 8px;
    cursor: pointer;
  }
}

#dropdownContent {
  display: none;
  border: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 1;
  text-align: center;
  color: gray;
  font-weight: 500;
  bottom: 10px;
  height: 30px;
}

.policy-container:hover #dropdownContent {
  display: block;
}

.hello {
  display: flex;
  justify-content: center;
}

.image_container {
  position: relative;
}

.home_closed_img {
  margin-top: 50px;
}

.closed_outlet_img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.card_operation_time {
  background-color: #f85757;
  margin-top: -20px;
}

.not_found_container {
  position: relative;
}

.home_not_found_img {
  margin-top: 60px;
}

.is_expired_message {
  background-color: #f85757;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.closed_x_img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
